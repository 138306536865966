import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const LocationPin = ({
  color,
  getRotation,
  className,
  ...props
}: IconProps): JSX.Element => (
  <svg
    className={className}
    {...props}
    fill={color || 'currentColor'}
    width="14"
    height="16"
    viewBox="0 0 14 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.04 0.12C6.96 0.12 6.89 0.12 6.81 0.12C3.60 0.23 0.96 2.72 0.65 5.91C0.59 6.52 0.62 7.14 0.73 7.73C0.73 7.73 0.74 7.81 0.78 7.95C0.88 8.39 1.02 8.81 1.21 9.21C1.87 10.77 3.32 13.17 6.46 15.78C6.62 15.91 6.83 15.99 7.04 15.99C7.26 15.99 7.47 15.91 7.63 15.78C10.77 13.18 12.22 10.77 12.87 9.22C13.06 8.81 13.21 8.39 13.31 7.95C13.33 7.87 13.34 7.81 13.35 7.75C13.43 7.35 13.47 6.94 13.47 6.53C13.46 3.00 10.58 0.12 7.04 0.12ZM3.72 6.60C3.72 4.77 5.20 3.28 7.04 3.28C8.87 3.28 10.36 4.78 10.36 6.60C10.36 8.43 8.87 9.92 7.04 9.92C5.20 9.92 3.72 8.43 3.72 6.60ZM4.51 6.60C4.51 7.99 5.64 9.13 7.04 9.13C8.43 9.13 9.56 7.99 9.56 6.60C9.56 5.21 8.43 4.08 7.04 4.08C5.65 4.08 4.51 5.21 4.51 6.60ZM12.53 7.77C12.55 7.67 12.56 7.62 12.56 7.61C12.63 7.25 12.66 6.89 12.66 6.53C12.66 3.37 10.03 0.81 6.84 0.92C4.03 1.02 1.71 3.19 1.44 5.98C1.39 6.52 1.42 7.07 1.52 7.60C1.52 7.60 1.52 7.60 1.52 7.61C1.52 7.61 1.52 7.61 1.52 7.62C1.52 7.62 1.52 7.62 1.52 7.62C1.53 7.65 1.54 7.70 1.55 7.77C1.64 8.15 1.77 8.53 1.93 8.89C2.56 10.38 3.95 12.66 6.96 15.16C6.99 15.19 7.02 15.19 7.04 15.19C7.06 15.19 7.09 15.19 7.12 15.16C10.14 12.67 11.52 10.38 12.14 8.90C12.31 8.53 12.44 8.15 12.53 7.77Z"
    />
  </svg>
);

export default LocationPin;
