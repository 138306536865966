import { Direction } from '@quno/patient-journey/types/Utilities';
import iconDefault from './iconDefaults';
import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const PhoneLines = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    transform={getRotation(Direction.RIGHT, 512, 512)}
    fill={color || 'currentColor'}
  >
    <g>
      <g>
        <g>
          <path d="M511.95,412.55c-0.71-19.80-8.96-38.28-23.22-52.04c-27.93-26.93-51.33-42.48-73.64-48.92    c-30.74-8.88-59.22-0.80-84.64,24.01c-0.03,0.03-0.07,0.07-0.11,0.11l-27.04,26.83    c-16.89-9.52-49.75-30.44-85.26-65.94l-2.63-2.63c-35.50-35.50-56.42-68.37-65.94-85.25l26.84-27.04    c0.03-0.03,0.07-0.07,0.11-0.11c24.82-25.41,32.90-53.89,24.01-84.64c-6.44-22.31-21.99-45.71-48.93-73.64    C137.73,9.01,119.25,0.76,99.44,0.05C79.62-0.66,60.61,6.23,45.86,19.47l-0.57,0.51    c-0.26,0.23-0.52,0.48-0.78,0.73C15.15,50.08-0.23,91.18,0.00,139.58c0.41,82.22,45.6,176.25,120.87,251.52    c14.19,14.19,30.32,28.24,47.95,41.75c8.76,6.72,21.31,5.06,28.04-3.70c6.72-8.76,5.06-21.32-3.70-28.04    c-16.23-12.44-31.04-25.33-44.00-38.29C81.17,294.83,40.36,211.30,40.00,139.39    c-0.18-37.27,11.02-68.38,32.43-90.01l0.15-0.13c14.53-13.04,36.54-12.25,50.10,1.80    c51.77,53.69,48.03,79.04,25.15,102.51l-37.07,37.35c-5.81,5.85-7.43,14.68-4.07,22.22    c0.94,2.11,23.71,52.42,80.41,109.13l2.63,2.62c56.69,56.69,107.01,79.46,109.12,80.40    c7.53,3.35,16.36,1.73,22.22-4.07l37.35-37.07c23.46-22.88,48.81-26.62,102.51,25.15    c14.06,13.56,14.85,35.57,1.81,50.10l-0.14,0.15c-21.44,21.22-52.23,32.43-89.10,32.43c-0.30,0-0.60,0-0.91-0.00    c-29.47-0.14-63.59-8.22-98.68-23.36c-10.14-4.37-21.91,0.3-26.28,10.44c-4.37,10.14,0.3,21.91,10.44,26.28    c40.56,17.49,79.02,26.45,114.33,26.63c0.37,0.00,0.74,0.00,1.12,0.00c47.91,0,88.60-15.37,117.73-44.51    c0.25-0.25,0.49-0.51,0.73-0.78l0.51-0.57C505.76,451.38,512.66,432.35,511.95,412.55z" />
        </g>
      </g>
    </g>
  </svg>
);

export default PhoneLines;
