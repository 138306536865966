import type { ImageProps } from 'next/image';

export type ContentfulImageConfig = {
  fit?: 'pad' | 'fill' | 'scale' | 'crop' | 'thumb';
  focus?:
    | 'center'
    | 'top'
    | 'left'
    | 'bottom'
    | 'top_right'
    | 'top_left'
    | 'bottom_right'
    | 'bottom_left'
    | 'face'
    | 'faces';
  isSvg?: boolean;
  height?: number;
};

export type ContentfulLoaderArgs = {
  src: ImageProps['src'];
  width?: string | number | undefined;
  quality?: string | number | undefined;
};

type ContentfulLoader = (args: ContentfulLoaderArgs) => string;

export const createCloudimgLoader = ({
  focus,
  isSvg,
  height: _height,
}: ContentfulImageConfig = {}): ContentfulLoader => {
  return ({
    src: _src,
    width: _width,
    quality = 75,
  }: ContentfulLoaderArgs): string => {
    let src = _src;
    let width = _width;
    let height = _height;

    let suffix = '';
    let proportion = (width as number) / (_height as number);
    let func = 'fit';

    const sanitizedSrc = src
      .toString()
      ?.replace(/^(\/)\W*/g, '')
      .replace(/^(https:\/\/)\W*/g, '');

    // -- we need an absolute url
    if (/^images\.ctfassets\.net/.test(sanitizedSrc as string)) {
      src = `https://img.qunomedical.com/${sanitizedSrc}`;
    }

    if (focus === 'face') {
      func = 'face';
    }

    // -- prevent return of images larger than 4000 which is not supported by contentful
    if (width && (width as number) >= 2000) {
      width = 1700;
      proportion = (width as number) / (_height as number);
    }

    if (height) {
      height = Math.round((_height as number) * proportion);
      suffix += `&h=${height}`;
    }

    return `${src}?w=${width}&q=${quality}&func=${func}${suffix}${
      !isSvg ? '&force_format=webp' : ''
    }&org_if_sml=1`;
  };
};

/**
 * Convert block id into a valid and useable id
 */
export const sanitizeContentfulBlockId = (id?: string): string => {
  const ID_REPLACE_REGEX = /[^a-zA-Z_]/g;
  return id ? id.replace(ID_REPLACE_REGEX, '') : '';
};
