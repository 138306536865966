import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const NL = ({ getRotation, ...props }: IconProps): JSX.Element => (
  <svg {...props} viewBox="0 0 640 480" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="evenodd" strokeWidth="1pt" transform="scale(1.25 .9375)">
      <rect fill="#fff" height="509.76" rx="0" ry="0" width="512" />
      <rect
        fill="#21468b"
        height="169.92"
        rx="0"
        ry="0"
        width="512"
        y="342.08"
      />
      <path d="M0 0h512v169.92H0z" fill="#ae1c28" />
    </g>
  </svg>
);

export default NL;
