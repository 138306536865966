import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const GR = ({ getRotation, ...props }: IconProps): JSX.Element => (
  <svg {...props} viewBox="0 0 27 18" xmlns="http://www.w3.org/2000/svg">
    <rect fill="#0D5EAF" width="27" height="18" />
    <path
      fill="none"
      strokeWidth="2"
      stroke="#FFF"
      d="M5,0V11 M0,5H10 M10,3H27 M10,7H27 M0,11H27 M0,15H27"
    />
  </svg>
);

export default GR;
