import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const AT = ({ getRotation, ...props }: IconProps): JSX.Element => (
  <svg {...props} viewBox="0 0 640 480" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="evenodd">
      <path d="M640 480H0V0h640z" fill="#fff" />
      <path d="M640 480H0V319.99h640zm0-319.87H0V.122h640z" fill="#df0000" />
    </g>
  </svg>
);

export default AT;
