import iconDefault from './iconDefaults';
import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const ClinicDoctorStay = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.3334 4.98438V7.28125H10.0001C9.4459 7.28125 9.00007 7.72012 9.00007 8.26562V10.2344C9.00007 10.7799 9.4459 11.2188 10.0001 11.2188H12.3334V13.5156C12.3334 14.0611 12.7793 14.5 13.3334 14.5H15.3335C15.8876 14.5 16.3335 14.0611 16.3335 13.5156V11.2188H18.6668C19.221 11.2188 19.6668 10.7799 19.6668 10.2344V8.26562C19.6668 7.72012 19.221 7.28125 18.6668 7.28125H16.3335V4.98438C16.3335 4.43887 15.8876 4 15.3335 4H13.3334C12.7793 4 12.3334 4.43887 12.3334 4.98438ZM26.3211 20.0863C27.0628 19.549 27.2211 18.5236 26.6753 17.7936C26.1294 17.0635 25.0877 16.9076 24.3461 17.4449L19.3585 21.0625H14.3335C13.9668 21.0625 13.6668 20.7672 13.6668 20.4062C13.6668 20.0453 13.9668 19.75 14.3335 19.75H15.0001H17.6668C18.4043 19.75 19.0002 19.1635 19.0002 18.4375C19.0002 17.7115 18.4043 17.125 17.6668 17.125H15.0001H14.3335H11.0709C9.85841 17.125 8.6834 17.5311 7.73755 18.2734L5.8667 19.75H4.33335C3.59584 19.75 3 20.3365 3 21.0625V23.6875C3 24.4135 3.59584 25 4.33335 25H11.0001H17.6877C18.896 25 20.0752 24.6186 21.0502 23.9131L26.3253 20.0863H26.3211ZM11.0293 19.75H11.0668C11.0543 19.75 11.0418 19.75 11.0293 19.75Z"
      fill={color}
    />
  </svg>
);

export default ClinicDoctorStay;
