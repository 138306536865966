import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const WhatsappAlternate = ({
  color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill={color}
  >
    <path
      d="M11.00 0H10.99C4.93 0 0 4.93 0 11C0 13.40 0.77 15.63 2.09 17.44L0.72 21.53L4.95 20.18C6.69 21.33 8.76 22 11.00 22C17.06 22 22 17.06 22 11C22 4.93 17.06 0 11.00 0Z"
      fill={color}
    />
    <path
      d="M17.40 15.53C17.13 16.28 16.08 16.90 15.24 17.08C14.67 17.20 13.91 17.30 11.39 16.25C8.15 14.91 6.07 11.63 5.91 11.42C5.76 11.20 4.60 9.68 4.60 8.10C4.60 6.52 5.41 5.75 5.73 5.42C5.99 5.15 6.43 5.03 6.85 5.03C6.99 5.03 7.11 5.03 7.22 5.04C7.55 5.05 7.71 5.07 7.92 5.58C8.19 6.22 8.83 7.80 8.91 7.96C8.99 8.12 9.07 8.34 8.96 8.56C8.85 8.78 8.76 8.87 8.60 9.06C8.44 9.25 8.28 9.39 8.12 9.59C7.97 9.77 7.81 9.95 7.99 10.28C8.18 10.59 8.83 11.65 9.78 12.50C11.01 13.59 12.00 13.94 12.36 14.09C12.62 14.20 12.94 14.17 13.13 13.96C13.38 13.70 13.68 13.26 13.99 12.83C14.21 12.51 14.49 12.48 14.78 12.59C15.08 12.69 16.65 13.47 16.97 13.63C17.30 13.79 17.51 13.87 17.59 14.00C17.66 14.14 17.66 14.78 17.40 15.53Z"
      fill="#ffffff"
    />
  </svg>
);

export default WhatsappAlternate;
