import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const Cash = ({
  getRotation,
  color = '#000',
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    viewBox="0 0 20 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.22222 0.735352C0.996528 0.735352 0 1.74653 0 2.99025V12.0099C0 13.2536 0.996528 14.2648 2.22222 14.2648H17.7778C19.0035 14.2648 20 13.2536 20 12.0099V2.99025C20 1.74653 19.0035 0.735352 17.7778 0.735352H2.22222ZM4.44444 12.0099H2.22222V9.75496C3.44792 9.75496 4.44444 10.7661 4.44444 12.0099ZM2.22222 5.24516V2.99025H4.44444C4.44444 4.23397 3.44792 5.24516 2.22222 5.24516ZM15.5556 12.0099C15.5556 10.7661 16.5521 9.75496 17.7778 9.75496V12.0099H15.5556ZM17.7778 5.24516C16.5521 5.24516 15.5556 4.23397 15.5556 2.99025H17.7778V5.24516ZM10 4.1177C10.8841 4.1177 11.7319 4.47406 12.357 5.10837C12.9821 5.74269 13.3333 6.603 13.3333 7.50006C13.3333 8.39711 12.9821 9.25743 12.357 9.89174C11.7319 10.5261 10.8841 10.8824 10 10.8824C9.11594 10.8824 8.2681 10.5261 7.64298 9.89174C7.01786 9.25743 6.66667 8.39711 6.66667 7.50006C6.66667 6.603 7.01786 5.74269 7.64298 5.10837C8.2681 4.47406 9.11594 4.1177 10 4.1177Z"
      fill={color}
    />
  </svg>
);

export default Cash;
