import { Direction } from '@quno/patient-journey/types/Utilities';
import iconDefault from './iconDefaults';
import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const Filters = ({
  color = iconDefault.color,
  getRotation,
  className,
  ...props
}: IconProps): JSX.Element => (
  <svg
    className={className}
    {...props}
    fill={color || 'currentColor'}
    transform={getRotation(Direction.RIGHT, 18, 20)}
    width="18"
    height="20"
    viewBox="0 0 18 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.33 2.91C12.56 1.57 13.72 0.55 15.12 0.55C16.68 0.55 17.95 1.82 17.95 3.39C17.95 4.96 16.68 6.23 15.12 6.23C13.71 6.23 12.55 5.20 12.33 3.87H0.62C0.36 3.87 0.15 3.65 0.15 3.39C0.15 3.12 0.36 2.91 0.62 2.91H12.33ZM13.24 3.38C13.24 4.42 14.08 5.27 15.12 5.27C16.16 5.27 17.00 4.42 17.00 3.38C17.00 2.35 16.16 1.50 15.12 1.50C14.08 1.50 13.24 2.35 13.24 3.38ZM17.48 9.56H5.77C5.54 8.23 4.38 7.20 2.98 7.20C1.42 7.20 0.15 8.47 0.15 10.04C0.15 11.61 1.42 12.88 2.98 12.88C4.38 12.88 5.54 11.85 5.77 10.51H17.48C17.74 10.51 17.95 10.30 17.95 10.04C17.95 9.78 17.74 9.56 17.48 9.56ZM1.10 10.04C1.10 11.08 1.95 11.93 2.98 11.93C4.02 11.93 4.86 11.08 4.86 10.04C4.86 9.00 4.02 8.16 2.98 8.16C1.95 8.16 1.10 9.00 1.10 10.04ZM15.12 13.91C13.72 13.91 12.56 14.93 12.33 16.27H0.62C0.36 16.27 0.15 16.49 0.15 16.75C0.15 17.02 0.36 17.23 0.62 17.23H12.33C12.55 18.57 13.71 19.6 15.12 19.6C16.68 19.6 17.95 18.32 17.95 16.76C17.95 15.19 16.68 13.91 15.12 13.91ZM13.24 16.75C13.24 17.79 14.08 18.63 15.12 18.63C16.16 18.63 17.00 17.79 17.00 16.75C17.00 15.71 16.16 14.86 15.12 14.86C14.08 14.86 13.24 15.71 13.24 16.75Z"
    />
  </svg>
);

export default Filters;
