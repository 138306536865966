import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const Whatsapp = ({ color, getRotation, ...props }: IconProps): JSX.Element => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={color}
  >
    <path d="M.057 24l1.68-6.16c-1.04-1.80-1.58-3.84-1.58-5.94.003-6.55 5.33-11.89 11.89-11.89 3.18.001 6.16 1.24 8.41 3.48 2.24 2.24 3.48 5.23 3.48 8.41-.003 6.55-5.33 11.89-11.89 11.89-1.99-.001-3.95-.5-5.68-1.44l-6.30 1.65zm6.59-3.80c1.67.995 3.27 1.59 5.39 1.59 5.44 0 9.88-4.43 9.88-9.88.002-5.46-4.41-9.89-9.88-9.89-5.45 0-9.88 4.43-9.88 9.88-.001 2.22.651 3.89 1.74 5.63l-.999 3.64 3.74-.981zm11.38-5.46c-.074-.124-.272-.198-.57-.347-.297-.149-1.75-.868-2.03-.967-.272-.099-.47-.149-.669.14-.198.29-.768.96-.941 1.16-.173.19-.347.22-.644.07-.297-.149-1.25-.462-2.39-1.47-.883-.788-1.48-1.76-1.65-2.05-.173-.297-.018-.458.13-.606.13-.133.29-.347.44-.521.15-.172.2-.296.3-.495.09-.198.05-.372-.025-.521-.075-.148-.669-1.61-.916-2.20-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.07-.792.37s-1.04 1.01-1.04 2.47 1.06 2.87 1.21 3.07c.149.19 2.09 3.2 5.07 4.48.709.30 1.26.489 1.69.626.71.226 1.36.19 1.87.118.57-.085 1.75-.719 2.00-1.41.248-.695.24-1.29.17-1.41z" />
  </svg>
);

export default Whatsapp;
