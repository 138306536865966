import AmericanExpress from './AmericanExpress';
import ApplePay from './ApplePay';
import BankTransfer from './BankTransfer';
import Cash from './Cash';
import CreditCard from './CreditCard';
import Maestro from './Maestro';
import Mastercard from './Mastercard';
import Paypal from './Paypal';
import Visa from './Visa';
import type { IconMap } from '@quno/patient-journey/src/components/ui/Icon';

export const Payment: IconMap = {
  AmericanExpress,
  ApplePay,
  BankTransfer,
  Cash,
  CreditCard,
  Maestro,
  Mastercard,
  Paypal,
  Visa,
};
