import iconDefault from './iconDefaults';
import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const Cardiology = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="58"
    height="47"
    viewBox="0 0 58 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M57.90 16.6C57.90 18.2 57.60 19.9 57.10 21.4C54.60 32.5 33.40 46 32.50 46.5C32.20 46.7 31.90 46.8 31.60 46.8C31.30 46.8 31.00 46.7 30.70 46.5C30.50 46.3 24.50 42.6 18.40 37.4C17.70 36.8 17.60 35.7 18.20 35C18.80 34.3 19.90 34.2 20.60 34.8C25.10 38.7 29.70 41.8 31.60 43C38.00 38.9 52.00 28.3 53.70 20.5C54.20 19.1 54.40 17.9 54.40 16.6C54.40 12.2 52.00 8.1 48.10 6C43.10 3.3 36.50 4.5 32.90 8.9C32.20 9.7 30.90 9.7 30.20 8.9C26.60 4.5 20.00 3.2 15.00 6C11.10 8.1 8.70 12.2 8.70 16.6C8.70 17.9 8.90 19.1 9.30 20.3C9.60 21.4 10.00 22.5 10.70 23.7H20.80L24.30 15.8C24.60 15.1 25.30 14.7 26.00 14.8C26.70 14.9 27.40 15.4 27.50 16.1L30.00 26.8L33.30 21.4C33.60 20.9 34.20 20.6 34.80 20.6H41.00C41.90 20.6 42.70 21.4 42.70 22.3C42.70 23.2 41.90 24 41.00 24H35.70L30.70 32.1C30.40 32.6 29.80 32.9 29.20 32.9H28.90C28.20 32.8 27.70 32.3 27.50 31.6L25.20 21.8L23.40 26C23.10 26.6 22.50 27 21.80 27H1.80C0.90 27 0.10 26.2 0.10 25.3C0.10 24.4 0.90 23.6 1.80 23.6H6.90C6.50 22.9 6.20 22 6.00 21.2C5.50 19.8 5.30 18.2 5.30 16.5C5.30 10.8 8.40 5.6 13.40 2.8C19.20 -0.4 26.80 0.6 31.60 5.1C36.30 0.7 44.00 -0.3 49.80 2.9C54.80 5.7 57.90 10.9 57.90 16.6Z"
      fill={color}
    />
  </svg>
);

export default Cardiology;
