import iconDefault from './iconDefaults';
import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const Suitcase = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.93 4.15H13.55V2.25C13.55 1.15 12.62 0.25 11.48 0.25H6.62C5.48 0.25 4.55 1.15 4.55 2.25V4.15H2.06C0.93 4.15 0 5.05 0 6.15V13L5 13V12C5 11.72 5.22 11.5 5.5 11.5C5.77 11.5 6 11.72 6 12V13L12 13V12C12 11.72 12.22 11.5 12.5 11.5C12.77 11.5 13 11.72 13 12V13H18L18 6.25C18 5.05 17.06 4.15 15.93 4.15ZM12 14V15C12 15.27 12.22 15.5 12.5 15.5C12.77 15.5 13 15.27 13 15V14H18L18 15.75C18 16.85 17.06 17.75 15.93 17.75H2.06C0.93 17.75 0 16.85 0 15.75V14L5 14V15C5 15.27 5.22 15.5 5.5 15.5C5.77 15.5 6 15.27 6 15V14L12 14ZM5.90 2.25C5.90 1.75 6.30 1.45 6.70 1.45H11.4C11.8 1.45 12.2 1.85 12.2 2.25V4.15H5.90V2.25Z"
      fill={color}
    />
  </svg>
);

export default Suitcase;
