import iconDefault from './iconDefaults';
import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const Reviews = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    viewBox="0 0 21 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2544_37044)">
      <path
        d="M11.5537 0.667969C11.3604 0.259766 10.9521 0 10.5037 0C10.0552 0 9.65054 0.259766 9.45366 0.667969L7.10939 5.57754L1.87397 6.36426C1.43647 6.43105 1.07189 6.74277 0.936994 7.16953C0.802099 7.59629 0.911473 8.06758 1.22502 8.38301L5.02397 12.209L4.1271 17.6158C4.05418 18.0611 4.23647 18.5139 4.59741 18.7773C4.95835 19.0408 5.43595 19.0742 5.8297 18.8627L10.5073 16.3207L15.1849 18.8627C15.5787 19.0742 16.0563 19.0445 16.4172 18.7773C16.7781 18.5102 16.9604 18.0611 16.8875 17.6158L15.987 12.209L19.786 8.38301C20.0995 8.06758 20.2125 7.59629 20.074 7.16953C19.9354 6.74277 19.5745 6.43105 19.137 6.36426L13.8979 5.57754L11.5537 0.667969Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_2544_37044">
        <rect width="21" height="19" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Reviews;
