import iconDefault from './iconDefaults';
import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const Facebook = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M9 8h-3v4h3v12h5v-12h3.64l.358-4h-4v-1.66c0-.955.19-1.33 1.11-1.33h2.88v-5h-3.80c-3.59 0-5.19 1.58-5.19 4.61v3.38z"
    />
  </svg>
);

export default Facebook;
