import iconDefault from './iconDefaults';
import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const LowestPriceGuarantee = ({
  color = iconDefault.color,
  getRotation,
  className,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    className={className}
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.75 0C1.64 0 0.75 0.89 0.75 2V9.91C0.75 10.70 1.22 11.42 1.94 11.74L6.63 13.80C7.14 14.03 7.73 14.03 8.24 13.80L12.93 11.74C13.66 11.42 14.13 10.70 14.13 9.91V2C14.13 0.89 13.23 0 12.13 0H2.75ZM8.71 5.03L10.44 5.14C10.67 5.16 10.87 5.30 10.95 5.51C11.02 5.72 10.96 5.95 10.78 6.09L9.42 7.12C9.39 7.15 9.38 7.18 9.39 7.21L9.80 8.77C9.84 8.94 9.80 9.11 9.69 9.24C9.58 9.36 9.42 9.44 9.25 9.44C9.14 9.44 9.03 9.41 8.94 9.35L7.47 8.51C7.44 8.49 7.40 8.49 7.37 8.51L5.91 9.35C5.82 9.41 5.72 9.44 5.61 9.44C5.43 9.44 5.26 9.36 5.15 9.23C5.05 9.10 5.01 8.93 5.06 8.77L5.47 7.21C5.48 7.18 5.46 7.15 5.44 7.12L4.10 6.09C3.92 5.95 3.85 5.72 3.93 5.51C4.00 5.30 4.20 5.16 4.44 5.15L6.16 5.03C6.20 5.03 6.23 5.01 6.24 4.98L6.90 3.48C7.00 3.27 7.20 3.14 7.44 3.14C7.68 3.14 7.88 3.27 7.97 3.48L8.63 4.97C8.65 5.00 8.68 5.03 8.71 5.03Z"
      fill={color}
    />
  </svg>
);

export default LowestPriceGuarantee;
