import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const Visa = ({ getRotation, ...props }: IconProps): JSX.Element => (
  <svg
    {...props}
    width="31"
    height="11"
    viewBox="0 0 31 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.05 9.97H5.44L3.49 2.52C3.40 2.17 3.20 1.87 2.91 1.73C2.19 1.37 1.39 1.08 0.52 0.94V0.65H4.72C5.30 0.65 5.73 1.08 5.81 1.58L6.82 6.96L9.42 0.65H11.96L8.05 9.97ZM13.40 9.97H10.94L12.97 0.65H15.43L13.40 9.97ZM18.61 3.23C18.68 2.73 19.12 2.45 19.63 2.45C20.42 2.37 21.29 2.52 22.01 2.87L22.45 0.87C21.72 0.58 20.93 0.44 20.21 0.44C17.82 0.44 16.08 1.73 16.08 3.52C16.08 4.88 17.31 5.60 18.18 6.03C19.12 6.46 19.48 6.75 19.41 7.17C19.41 7.82 18.68 8.11 17.96 8.11C17.09 8.11 16.22 7.89 15.43 7.53L15 9.54C15.86 9.90 16.80 10.04 17.67 10.04C20.35 10.11 22.01 8.82 22.01 6.89C22.01 4.45 18.61 4.31 18.61 3.23ZM30.62 9.97L28.67 0.65H26.57C26.14 0.65 25.70 0.94 25.56 1.37L21.94 9.97H24.47L24.98 8.61H28.09L28.38 9.97H30.62ZM26.93 3.16L27.66 6.67H25.63L26.93 3.16Z"
      fill="#172B85"
    />
  </svg>
);

export default Visa;
