import iconDefault from './iconDefaults';
import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const Balance = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="44"
    height="50"
    viewBox="0 0 44 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.8 7.1H35.4C39.7 7.1 43.20 10.6 43.3 15V42C43.3 46.4 39.7 49.9 35.4 49.9C34.5 49.9 33.8 49.2 33.8 48.3C33.8 47.4 34.5 46.7 35.4 46.7C38 46.7 40.1 44.6 40.1 42V28.9H3.90V42C3.90 44.6 6.00 46.7 8.60 46.7H27C27.9 46.7 28.6 47.4 28.6 48.3C28.6 49.2 27.9 49.9 27 49.9H8.70C4.30 49.9 0.80 46.3 0.80 42V15C0.80 10.6 4.40 7.1 8.70 7.1H10.3C12 2.9 16.7 0 22 0C27.3 0 32 2.9 33.8 7.1ZM3.90 15V25.7L40.1 25.8V15C40.1 12.4 38 10.3 35.4 10.3H32.6C31.9 10.3 31.3 9.8 31.1 9.1C30.20 5.7 26.4 3.2 22 3.2C17.6 3.2 13.8 5.7 12.9 9.1C12.7 9.8 12.1 10.3 11.4 10.3H8.60C6.00 10.3 3.90 12.4 3.90 15ZM22 10.8C17 10.8 12.9 14.9 12.9 19.9C12.9 20.8 13.6 21.5 14.5 21.5H29.5C30.4 21.5 31.1 20.8 31.1 19.9C31 14.8 27 10.8 22 10.8ZM22.1 15.7L23.1 18.2H27.7C27 15.7 24.7 13.9 22 13.9C19.3 13.9 17 15.7 16.3 18.2H21.3L22.1 15.7Z"
      fill={color}
    />
  </svg>
);

export default Balance;
