import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const RU = ({ getRotation, ...props }: IconProps): JSX.Element => (
  <svg {...props} viewBox="0 0 640 480" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 320h640v160.00H0z" fill="#d52b1e" />
    <path d="M0 0h640v160H0z" fill="#ffffff" />
    <path d="M0 160h640v160H0z" fill="#0039a6" />
  </svg>
);

export default RU;
