import iconDefault from './iconDefaults';
import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const Plane = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0719 6C16.1406 6 18 6.90625 18 8C18 9.125 16.1406 10 15.0719 10H11.4281L8.2875 15.4969C8.10938 15.8094 7.77812 16 7.41875 16H5.6625C5.33125 16 5.09063 15.6813 5.18125 15.3625L6.7125 10H3.5L2.15 11.8C2.05625 11.925 1.90625 12 1.75 12H0.4375C0.19375 12 0 11.8031 0 11.5625C0 11.5219 0.00625 11.4812 0.015625 11.4406L1 8L0.015625 4.55937C0.003125 4.51875 0 4.47813 0 4.4375C0 4.19375 0.196875 4 0.4375 4H1.75C1.90625 4 2.05625 4.075 2.15 4.2L3.5 6H6.71562L5.18437 0.6375C5.09062 0.31875 5.33125 0 5.6625 0H7.41875C7.77812 0 8.10938 0.19375 8.2875 0.503125L11.4281 6H15.0719Z"
      fill={color}
    />
  </svg>
);

export default Plane;
