import iconDefault from './iconDefaults';
import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const ServiceFeePriceTag = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.93 4.87H26.68C25.81 4.87 24.58 5.38 23.96 6.00L5.80 24.16C4.56 25.40 4.56 27.43 5.80 28.67L19.32 42.19C20.56 43.43 22.59 43.43 23.83 42.19L41.99 24.03C42.61 23.41 43.12 22.18 43.12 21.31V8.06C43.12 6.30 41.69 4.87 39.93 4.87ZM34.42 14.74C34.89 14.26 35.66 14.26 36.14 14.74C36.61 15.23 36.61 16.01 36.14 16.50L21.54 31.35C21.07 31.84 20.30 31.84 19.82 31.35L13.74 25.16C13.27 24.68 13.27 23.89 13.74 23.41C14.22 22.93 14.99 22.93 15.46 23.41L20.68 28.73L34.42 14.74ZM38.55 11.66C39.77 11.66 40.76 10.66 40.76 9.44C40.76 8.22 39.77 7.23 38.55 7.23C37.33 7.23 36.34 8.22 36.34 9.44C36.34 10.66 37.33 11.66 38.55 11.66Z"
      fill={color}
    />
  </svg>
);

export default ServiceFeePriceTag;
