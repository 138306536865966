import iconDefault from './iconDefaults';
import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const Currency = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="24"
    height="16"
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 1H21C22.10 1 23 1.89 23 3V14"
      stroke={color}
      strokeWidth="1.5"
    />
    <g clipPath="url(#clip0)">
      <rect
        x="0.75"
        y="3.75"
        width="19.5"
        height="11.5"
        rx="1.25"
        fill="none"
        stroke={color}
        strokeWidth="1.5"
      />
      <circle cx="1.5" cy="4.5" r="3.5" fill={color} />
      <circle cx="19.5" cy="4.5" r="3.5" fill={color} />
      <circle cx="19.5" cy="14.5" r="3.5" fill={color} />
      <circle cx="1.5" cy="14.5" r="3.5" fill={color} />
    </g>
    <circle cx="10.5" cy="9.5" r="3.5" fill={color} />
    <defs>
      <clipPath id="clip0">
        <rect y="3" width="21" height="13" rx="2" fill="none" />
      </clipPath>
    </defs>
  </svg>
);

export default Currency;
