import { PRODUCTION } from '@quno/patient-journey/src/constants/environment';
import { Fragment, createElement } from 'react';
import config from '@quno/patient-journey/config';
import { useRouter } from 'next/router';
import localesContent from '@quno/patient-journey/src/locales';
import type { Locale } from '@quno/patient-journey/src/locales';

export type TranslationFunction = (
  str: string,
  args?: Record<string, number | string | JSX.Element | undefined>,
  asString?: boolean,
  keyFallback?: boolean,
  element?: string,
) => string;

type Translation = {
  t: TranslationFunction;
  locale: Locale;
};

export const useTranslation = (fallbackToKey = false): Translation => {
  const { locale: routerLocale } = useRouter();

  const locale = (
    routerLocale === 'default' ? config.defaultLocale : routerLocale
  ) as Locale;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const t: TranslationFunction = (
    localeType,
    args?,
    asString = false,
    keyFallback = fallbackToKey,
    element = undefined,
  ) => {
    const localeObject = localesContent?.[locale as Locale] || null;

    const defaultLocaleObject =
      localesContent?.[config.defaultLocale as Locale] || null;

    const localeValue =
      (localeObject?.[localeType as keyof typeof localeObject] as string) ||
      null;

    const defaultLocaleValue =
      (defaultLocaleObject?.[
        localeType as keyof typeof defaultLocaleObject
      ] as string) || null;

    const localeValueOrDefault = localeValue || defaultLocaleValue || '';

    if (!localeValue) {
      if (!fallbackToKey && !keyFallback) {
        if (!PRODUCTION) {
          console.warn(
            `Translation '${localeType}' for locale '${locale}' not found.`,
          );
        }

        return '';
      }
    }

    if (args) {
      const tokens = localeValueOrDefault
        .split(/(\{.*?\})/g)
        .map((token: string) => {
          if (/\{.*?\}/.test(token)) {
            const key = token.replace(/[{}]+/g, '');

            if (element) {
              return createElement(element, {}, args[key]);
            }

            return args[key];
          }

          return token;
        });

      if (asString) {
        return tokens.join('');
      }

      return (
        <>
          {tokens.map((token, i: number) => (
            <Fragment key={i}>{token}</Fragment>
          ))}
        </>
      );
    }

    if ((fallbackToKey && keyFallback) || keyFallback) {
      if (!localeValueOrDefault) {
        return localeType;
      }
    }

    return localeValueOrDefault;
  };

  return {
    t,
    locale,
  };
};
