import iconDefault from './iconDefaults';
import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const Airplane = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.70 1.30C17.60 1.10 17.50 0.80 17.30 0.70C17.10 0.50 16.90 0.40 16.70 0.30C16.50 0.20 16.20 0.20 16.00 0.20C15.80 0.20 15.50 0.30 15.30 0.40C15.10 0.50 14.90 0.70 14.70 0.80L10.70 4.80L9.10 4.50L9.90 3.70C10.10 3.50 10.10 3.10 9.90 2.90C9.70 2.70 9.30 2.70 9.10 2.90L7.70 4.20L6.00 3.80L6.70 3.10C6.90 2.90 6.90 2.50 6.70 2.30C6.50 2.10 6.10 2.10 5.90 2.30L4.60 3.50L2.80 3.10C2.30 3.00 1.50 3.10 1.20 3.40L0.50 4.10C0.30 4.30 0.20 4.60 0.20 4.80C0.30 5.40 1.00 5.70 1.90 6.10C2.00 6.10 2.10 6.20 2.20 6.20L2.50 6.30C4.00 7.10 5.40 7.70 6.80 8.50C6.50 8.90 6.10 9.20 5.80 9.60C5.30 10.1 4.90 10.6 4.30 11.1H2.80C2.40 11.1 2.10 11.2 1.80 11.5L1.50 11.8C1.20 12.1 1.00 12.4 1.10 12.8C1.20 13.3 1.70 13.5 1.90 13.6L3.70 14.3L4.40 16.1C4.50 16.3 4.70 16.8 5.20 16.9C5.60 17 6.00 16.7 6.20 16.5L6.50 16.2C6.80 15.9 6.90 15.4 6.90 15L6.80 13.8L7.30 13.3C8.00 12.6 8.70 11.8 9.50 11.2C10.20 12.6 10.90 14.1 11.60 15.6L11.70 15.9C11.70 16 11.80 16.1 11.80 16.2C12.20 17.1 12.50 17.7 13.10 17.8H13.30C13.50 17.8 13.70 17.7 13.90 17.5L14.60 16.8C15.00 16.4 15.10 15.8 14.90 15.2L14.50 13.4L16.00 11.9C16.20 11.7 16.20 11.3 16.00 11.1C15.80 10.9 15.40 10.9 15.20 11.1L14.20 12.1L13.80 10.4L15.00 8.90C15.20 8.70 15.20 8.30 15.00 8.10C14.80 7.90 14.40 7.90 14.20 8.10L13.50 8.80L13.20 7.20L17.20 3.20C17.40 3.00 17.50 2.80 17.60 2.60C17.70 2.40 17.80 2.20 17.80 1.90C17.80 1.70 17.80 1.50 17.70 1.30Z"
      fill={color}
    />
  </svg>
);

export default Airplane;
