import iconDefault from './iconDefaults';
import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const SuccessAlternate = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48ZM9.37247 26.8114L14.9813 32.4203L18.9458 36.3847C19.3363 36.7752 19.9694 36.7752 20.36 36.3847L24.3244 32.4203L38.3365 18.4082C39.1963 17.5483 39.1963 16.1537 38.3365 15.2938L36.7793 13.7367C35.9194 12.8768 34.5248 12.8768 33.6649 13.7367L19.6529 27.7487L14.044 22.1399C13.1841 21.28 11.7895 21.28 10.9296 22.1399L9.37247 23.697C8.51262 24.5569 8.51262 25.9515 9.37247 26.8114Z"
      fill={color}
    />
  </svg>
);

export default SuccessAlternate;
