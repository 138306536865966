import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const ApplePay = ({ getRotation, ...props }: IconProps): JSX.Element => (
  <svg
    {...props}
    width="31"
    height="14"
    viewBox="0 0 31 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.76 2.25C5.40 2.68 4.83 3.02 4.25 2.97C4.18 2.39 4.46 1.76 4.79 1.38C5.15 0.94 5.78 0.62 6.29 0.60C6.35 1.20 6.12 1.80 5.76 2.25ZM6.29 3.09C5.78 3.06 5.32 3.24 4.94 3.39C4.70 3.49 4.49 3.57 4.34 3.57C4.16 3.57 3.95 3.48 3.71 3.38C3.40 3.26 3.04 3.12 2.67 3.12C1.81 3.13 1.01 3.63 0.57 4.41C-0.32 5.98 0.33 8.31 1.21 9.59C1.63 10.22 2.14 10.92 2.82 10.89C3.11 10.88 3.33 10.79 3.55 10.69C3.80 10.59 4.07 10.47 4.48 10.47C4.88 10.47 5.13 10.58 5.37 10.69C5.60 10.79 5.82 10.89 6.15 10.88C6.85 10.87 7.29 10.25 7.71 9.61C8.17 8.93 8.38 8.27 8.41 8.17L8.41 8.16C8.41 8.16 8.41 8.15 8.40 8.15C8.24 8.08 7.06 7.53 7.05 6.07C7.04 4.84 7.99 4.22 8.13 4.12L8.13 4.12C8.14 4.11 8.15 4.11 8.15 4.11C7.55 3.21 6.61 3.11 6.29 3.09ZM11.12 10.81V1.32H14.64C16.45 1.32 17.72 2.59 17.72 4.44C17.72 6.29 16.43 7.57 14.59 7.57H12.57V10.81H11.12ZM12.57 2.56H14.25C15.52 2.56 16.24 3.24 16.24 4.44C16.24 5.64 15.52 6.33 14.25 6.33H12.57V2.56ZM22.53 9.67C22.14 10.42 21.30 10.89 20.38 10.89C19.03 10.89 18.08 10.07 18.08 8.84C18.08 7.62 19.00 6.92 20.69 6.82L22.51 6.71V6.18C22.51 5.41 22.01 4.99 21.12 4.99C20.38 4.99 19.85 5.38 19.74 5.96H18.43C18.47 4.73 19.61 3.83 21.16 3.83C22.82 3.83 23.91 4.72 23.91 6.09V10.81H22.56V9.67H22.53ZM20.77 9.76C20.00 9.76 19.50 9.38 19.50 8.80C19.50 8.21 19.98 7.86 20.89 7.80L22.51 7.70V8.24C22.51 9.13 21.76 9.76 20.77 9.76ZM28.38 11.18C27.79 12.85 27.13 13.39 25.71 13.39C25.60 13.39 25.24 13.38 25.15 13.36V12.22C25.24 12.23 25.47 12.24 25.58 12.24C26.22 12.24 26.58 11.97 26.81 11.26L26.94 10.84L24.47 3.93H25.99L27.71 9.53H27.74L29.45 3.93H30.93L28.38 11.18Z"
      fill="white"
    />
  </svg>
);

export default ApplePay;
