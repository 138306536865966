import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const LocationPinAlternate = ({
  color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.42 0.00C7.51 0 7.61 0 7.70 0C11.95 0 15.40 3.45 15.41 7.69C15.41 8.18 15.36 8.67 15.27 9.15C15.26 9.22 15.24 9.30 15.22 9.39C15.10 9.91 14.93 10.43 14.70 10.91C13.91 12.77 12.18 15.66 8.41 18.78C8.21 18.95 7.96 19.04 7.70 19.04C7.45 19.04 7.20 18.95 7.00 18.78C3.23 15.66 1.49 12.77 0.70 10.90C0.48 10.42 0.30 9.91 0.19 9.39C0.14 9.22 0.13 9.13 0.13 9.13C0.00 8.42 -0.03 7.68 0.03 6.94C0.40 3.12 3.58 0.13 7.42 0.00ZM4.67 7.77C4.67 9.44 6.02 10.80 7.70 10.80C9.37 10.80 10.73 9.44 10.73 7.77C10.73 6.11 9.37 4.75 7.70 4.75C6.03 4.75 4.67 6.11 4.67 7.77Z"
      fill={color}
    />
  </svg>
);

export default LocationPinAlternate;
