import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const CreditCard = ({
  getRotation,
  color = '#000',
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.7778 2.41729C18.0833 2.41729 18.3333 2.66614 18.3333 2.97028V4.07627H1.66667V2.97028C1.66667 2.66614 1.91667 2.41729 2.22222 2.41729H17.7778ZM18.3333 7.39425V14.0302C18.3333 14.3343 18.0833 14.5832 17.7778 14.5832H2.22222C1.91667 14.5832 1.66667 14.3343 1.66667 14.0302V7.39425H18.3333ZM2.22222 0.758301C0.996528 0.758301 0 1.75024 0 2.97028V14.0302C0 15.2502 0.996528 16.2422 2.22222 16.2422H17.7778C19.0035 16.2422 20 15.2502 20 14.0302V2.97028C20 1.75024 19.0035 0.758301 17.7778 0.758301H2.22222ZM4.16667 11.2652C3.70486 11.2652 3.33333 11.635 3.33333 12.0947C3.33333 12.5544 3.70486 12.9242 4.16667 12.9242H5.83333C6.29514 12.9242 6.66667 12.5544 6.66667 12.0947C6.66667 11.635 6.29514 11.2652 5.83333 11.2652H4.16667ZM8.61111 11.2652C8.14931 11.2652 7.77778 11.635 7.77778 12.0947C7.77778 12.5544 8.14931 12.9242 8.61111 12.9242H12.5C12.9618 12.9242 13.3333 12.5544 13.3333 12.0947C13.3333 11.635 12.9618 11.2652 12.5 11.2652H8.61111Z"
      fill={color}
    />
  </svg>
);

export default CreditCard;
