import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const CH = ({ getRotation, ...props }: IconProps): JSX.Element => (
  <svg {...props} viewBox="0 0 640 480" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="evenodd" strokeWidth="1pt">
      <path d="M0 0h640v480H0z" fill="#d52b1e" />
      <g fill="#fff">
        <path d="M170 194.99h299.99v89.99H170z" />
        <path d="M275 89.99h89.99v299.99H275z" />
      </g>
    </g>
  </svg>
);

export default CH;
