import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const BankTransfer = ({ getRotation, ...props }: IconProps): JSX.Element => (
  <svg
    {...props}
    width="31"
    height="15"
    viewBox="0 0 31 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.16 8.35C4.96 8.35 6.29 7.79 6.29 6.16C6.29 5.05 5.69 4.36 4.37 4.20V4.18C5.21 3.96 5.94 3.39 5.94 2.32C5.94 1.23 5.39 0.37 3.43 0.37H0.14V8.35H3.16ZM1.90 7.04V4.95H2.98C3.77 4.95 4.41 5.11 4.41 6.00C4.41 6.87 3.77 7.04 2.81 7.04H1.90ZM1.90 3.66V1.68H2.82C3.61 1.68 4.21 1.73 4.21 2.59C4.21 3.52 3.38 3.66 2.56 3.66H1.90Z"
      fill="#155263"
    />
    <path
      d="M9.35 0.37L6.64 8.35H8.46L9.10 6.41H11.87L12.55 8.35H14.49L11.56 0.37H9.35ZM11.50 5.05H9.40L10.42 1.97H10.47L11.50 5.05Z"
      fill="#155263"
    />
    <path
      d="M22.31 8.35V0.37H20.70V6.02L17.56 0.37H15.45V8.35H17.07V2.72L20.27 8.35H22.31Z"
      fill="#155263"
    />
    <path
      d="M25.75 0.37H24.00V8.35H25.75V5.85L26.55 5.01L28.74 8.35H30.81L27.81 3.69L30.85 0.37H28.97L25.75 3.79V0.37Z"
      fill="#155263"
    />
    <path
      d="M2.33 14.74V11.20H3.59V10.42H0.14V11.20H1.41V14.74H2.33Z"
      fill="#155263"
    />
    <path
      d="M6.44 12.87C6.88 12.67 7.19 12.28 7.19 11.64C7.19 11.00 6.83 10.42 5.76 10.42H4.08V14.74H4.99V13.10H5.58L6.43 14.74H7.42L6.44 12.87ZM4.99 12.38V11.16H5.50C5.98 11.16 6.24 11.32 6.24 11.73C6.24 12.22 5.89 12.38 5.46 12.38H4.99Z"
      fill="#155263"
    />
    <path
      d="M8.96 10.42L7.54 14.74H8.49L8.83 13.69H10.26L10.62 14.74H11.63L10.10 10.42H8.96ZM10.07 12.95H8.98L9.51 11.29H9.54L10.07 12.95Z"
      fill="#155263"
    />
    <path
      d="M15.69 14.74V10.42H14.86V13.48L13.23 10.42H12.13V14.74H12.97V11.69L14.63 14.74H15.69Z"
      fill="#155263"
    />
    <path
      d="M17.92 11.05C18.43 11.05 18.59 11.30 18.63 11.70L19.47 11.61C19.40 10.58 18.70 10.34 17.93 10.34C17.06 10.34 16.36 10.80 16.36 11.64C16.36 13.28 18.60 12.65 18.60 13.55C18.60 13.95 18.27 14.08 17.89 14.08C17.26 14.08 17.10 13.73 17.03 13.31L16.20 13.44C16.27 14.56 17.09 14.81 17.86 14.81C18.72 14.81 19.52 14.42 19.52 13.44C19.52 11.86 17.27 12.41 17.27 11.51C17.27 11.24 17.48 11.05 17.92 11.05Z"
      fill="#155263"
    />
    <path
      d="M21.04 13.04H22.70V12.29H21.04V11.19H22.84V10.42H20.12V14.74H21.04V13.04Z"
      fill="#155263"
    />
    <path
      d="M24.34 12.95H26.05V12.20H24.34V11.19H26.15V10.42H23.42V14.74H26.26V13.96H24.34V12.95Z"
      fill="#155263"
    />
    <path
      d="M29.26 12.87C29.70 12.67 30.01 12.28 30.01 11.64C30.01 11.00 29.65 10.42 28.57 10.42H26.89V14.74H27.81V13.10H28.40L29.25 14.74H30.24L29.26 12.87ZM27.81 12.38V11.16H28.31C28.80 11.16 29.06 11.32 29.06 11.73C29.06 12.22 28.71 12.38 28.28 12.38H27.81Z"
      fill="#155263"
    />
  </svg>
);

export default BankTransfer;
