import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const VerifiedClinic = ({
  getRotation,
  className,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    className={className}
    width="17"
    height="27"
    viewBox="0 0 17 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 24.61V1C0 0.44 0.44 0 1 0H16C16.55 0 17 0.44 17 0.99V24.61C17 25.49 15.93 25.94 15.29 25.32L9.20 19.31C8.81 18.93 8.18 18.93 7.79 19.31L1.70 25.32C1.06 25.94 0 25.49 0 24.61Z"
      fill="#FFCA58"
    />
    <path
      opacity="0.5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.93 9.94C7.04 9.82 7.11 9.65 7.11 9.47V8.08V8.08H8.50C8.55 8.08 8.60 8.08 8.65 8.07C8.74 8.05 8.83 8.01 8.91 7.96C9.11 7.82 9.24 7.60 9.24 7.34V5.86C9.24 5.61 9.11 5.38 8.91 5.25C8.83 5.19 8.74 5.15 8.65 5.13C8.60 5.12 8.55 5.12 8.50 5.12H7.79C7.54 5.29 7.32 5.48 7.12 5.70C6.51 6.36 6.14 7.24 6.14 8.20C6.14 8.47 6.17 8.72 6.22 8.97C6.26 9.14 6.31 9.30 6.36 9.46C6.42 9.62 6.49 9.77 6.57 9.92C6.60 9.99 6.64 10.06 6.69 10.13C6.72 10.12 6.75 10.10 6.78 10.08C6.83 10.04 6.89 10.00 6.93 9.94"
      fill="#155263"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.55 10.79C12.46 10.89 12.36 10.98 12.26 11.06L11.40 9.91C11.23 9.69 10.91 9.64 10.69 9.81C10.55 9.91 10.48 10.07 10.48 10.23C10.49 10.30 10.50 10.36 10.53 10.43C10.54 10.46 10.56 10.49 10.58 10.51L11.39 11.60C10.93 11.80 10.42 11.92 9.88 11.92C8.53 11.92 7.34 11.20 6.69 10.13C6.64 10.06 6.60 9.99 6.57 9.92C6.49 9.77 6.42 9.62 6.36 9.46C6.31 9.30 6.26 9.14 6.22 8.97C6.17 8.72 6.14 8.47 6.14 8.20C6.14 7.24 6.51 6.36 7.12 5.70C7.32 5.48 7.54 5.29 7.79 5.12C8.39 4.72 9.11 4.48 9.88 4.48C11.94 4.48 13.61 6.15 13.61 8.20C13.61 8.56 13.56 8.91 13.47 9.24C13.43 9.37 13.38 9.50 13.33 9.62C13.28 9.75 13.22 9.87 13.15 9.99C13.09 10.11 13.02 10.22 12.94 10.33C12.90 10.38 12.86 10.44 12.82 10.49C12.74 10.60 12.65 10.70 12.55 10.79V10.79ZM12.86 11.88C13.94 11.01 14.63 9.68 14.63 8.20C14.63 5.59 12.50 3.47 9.88 3.47C8.85 3.47 7.89 3.80 7.11 4.36V3.74C7.11 3.33 6.77 3.00 6.36 3.00H4.87C4.46 3.00 4.13 3.33 4.13 3.74V5.12H2.74C2.33 5.12 2 5.45 2 5.86V7.34C2 7.75 2.33 8.08 2.74 8.08H4.13V9.47C4.13 9.87 4.46 10.21 4.87 10.21H5.58C6.34 11.81 7.98 12.93 9.88 12.93C10.64 12.93 11.36 12.75 12.00 12.43L13.96 15.06C14.06 15.19 14.21 15.26 14.37 15.26C14.47 15.26 14.58 15.23 14.67 15.16C14.90 15.00 14.94 14.68 14.78 14.46L12.86 11.88Z"
      fill="#155263"
    />
  </svg>
);

export default VerifiedClinic;
