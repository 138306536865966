import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const IE = ({ getRotation, ...props }: IconProps): JSX.Element => (
  <svg {...props} viewBox="0 0 640 480" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="evenodd" strokeWidth="1pt">
      <path d="M0 0h639.99v480.00H0z" fill="#fff" />
      <path d="M0 0h213.33v480.00H0z" fill="#009A49" />
      <path d="M426.66 0h213.33v480.00H426.66z" fill="#FF7900" />
    </g>
  </svg>
);

export default IE;
