import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const Thumb = ({
  className,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    className={className}
    width="43"
    height="43"
    viewBox="0 0 43 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M3.85 16.18C1.72 16.18 0 17.91 0 20.04V32.37C0 34.49 1.72 36.22 3.85 36.22H8.47C9.34 36.22 10.14 35.93 10.79 35.44V16.18H3.85Z"
        fill="#155263"
      />
      <path
        d="M37.00 21.96C37.00 21.04 36.63 20.17 35.99 19.54C36.71 18.75 37.08 17.69 36.98 16.59C36.80 14.63 35.03 13.10 32.96 13.10H23.43C23.91 11.67 24.66 9.04 24.66 6.93C24.66 3.59 21.82 0.77 20.04 0.77C18.44 0.77 17.29 1.67 17.24 1.70C17.06 1.85 16.95 2.07 16.95 2.31V7.54L12.51 17.15L12.33 17.25V33.77C13.58 34.36 15.17 34.68 16.18 34.68H30.33C32.01 34.68 33.48 33.55 33.83 31.99C34.00 31.19 33.90 30.38 33.55 29.67C34.69 29.10 35.45 27.93 35.45 26.59C35.45 26.04 35.33 25.52 35.09 25.05C36.23 24.47 37.00 23.30 37.00 21.96Z"
        fill="#FFCA58"
      />
    </g>
  </svg>
);

export default Thumb;
