import iconDefault from './iconDefaults';
import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const Bell = ({
  color = iconDefault.color,
  secondaryColor = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="25"
    height="27"
    viewBox="0 0 25 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 23C8.01 25.35 10.32 27 13 27C15.67 27 17.98 25.35 19 23H7Z"
      fill={secondaryColor}
    />
    <path
      d="M12.49 2.38C13.51 2.38 14.49 2.56 15.40 2.89V2.78C15.40 1.24 14.15 0 12.61 0H12.38C10.84 0 9.59 1.24 9.59 2.78V2.89C10.50 2.56 11.48 2.38 12.49 2.38Z"
      fill={color}
    />
    <path
      d="M20.93 20.27H4.06C4.36 19.95 4.73 19.46 5.08 18.71C6.20 16.34 6.41 13.11 6.41 10.93C6.41 7.58 9.14 4.85 12.49 4.85C15.84 4.85 18.56 7.57 18.58 10.91L18.58 10.93L18.58 10.94C18.58 13.12 18.79 16.34 19.91 18.71C20.26 19.46 20.63 19.95 20.93 20.27Z"
      stroke={color}
      strokeWidth="2"
    />
  </svg>
);

export default Bell;
