import iconDefault from './iconDefaults';
import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const Instagram = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M12 2.16c3.20 0 3.58.012 4.85.07 3.25.148 4.77 1.69 4.91 4.91.058 1.26.069 1.64.069 4.84 0 3.20-.012 3.58-.069 4.84-.149 3.22-1.66 4.77-4.91 4.91-1.26.058-1.64.07-4.85.07-3.20 0-3.58-.012-4.84-.07-3.26-.149-4.77-1.69-4.91-4.92-.058-1.26-.07-1.64-.07-4.84 0-3.20.013-3.58.07-4.84.149-3.22 1.66-4.77 4.91-4.91 1.26-.057 1.64-.069 4.84-.069zm0-2.16c-3.25 0-3.66.014-4.94.072-4.35.2-6.78 2.61-6.98 6.98-.059 1.28-.073 1.68-.073 4.94 0 3.25.014 3.66.072 4.94.2 4.35 2.61 6.78 6.98 6.98 1.28.058 1.68.072 4.94.072 3.25 0 3.66-.014 4.94-.072 4.35-.2 6.78-2.61 6.97-6.98.05-1.28.07-1.68.073-4.94 0-3.25-.014-3.66-.072-4.94-.196-4.35-2.61-6.78-6.97-6.98-1.28-.059-1.69-.073-4.94-.073zm0 5.83c-3.40 0-6.16 2.75-6.16 6.16s2.75 6.16 6.16 6.16 6.16-2.75 6.16-6.16c0-3.40-2.75-6.16-6.16-6.16zm0 10.16c-2.20 0-4-1.79-4-4 0-2.20 1.79-4 4-4s4 1.79 4 4c0 2.21-1.79 4-4 4zm6.40-11.84c-.796 0-1.44.645-1.44 1.44s.645 1.44 1.44 1.44c.795 0 1.43-.645 1.43-1.44s-.644-1.44-1.43-1.44z"
    />
  </svg>
);

export default Instagram;
