import { Direction } from '@quno/patient-journey/types/Utilities';
import iconDefault from './iconDefaults';
import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const FiltersAlternate = ({
  color = iconDefault.color,
  getRotation,
  className,
  ...props
}: IconProps): JSX.Element => (
  <svg
    className={className}
    {...props}
    fill="none"
    transform={getRotation(Direction.RIGHT, 20, 20)}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 1C0.44 1 0 1.44 0 2C0 2.55 0.44 3 1 3H19C19.55 3 20 2.55 20 2C20 1.44 19.55 1 19 1H1ZM1 9C0.44 9 0 9.44 0 10C0 10.55 0.44 11 1 11H19C19.55 11 20 10.55 20 10C20 9.44 19.55 9 19 9H1ZM0 18C0 17.44 0.44 17 1 17H19C19.55 17 20 17.44 20 18C20 18.55 19.55 19 19 19H1C0.44 19 0 18.55 0 18Z"
      fill={color}
    />
    <circle cx="16" cy="10" r="2" fill={color} />
    <circle cx="10" cy="18" r="2" fill={color} />
    <circle cx="4" cy="2" r="2" fill={color} />
  </svg>
);

export default FiltersAlternate;
