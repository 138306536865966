import type { Asset } from 'contentful';
import type { ReactNode } from 'react';
import type { Media } from '@quno/patient-journey/types/Clinic';
import type { APIBeforeAfterPhoto } from './BeforeAfterPhotos';

export type MaybePromise<T> = Promise<T> | T;

export type PassiveEventHandler = (
  type: string,
  listener: (event: Event) => void,
  options?: {
    passive?: boolean;
  },
) => void;

export type Sys = {
  id: string;
  publishedAt: string;
  firstPublishedAt: string;
};

export type City = {
  name: string;
  country: {
    name: string;
    acronym?: string;
  };
};

export type Country = {
  phone?: string;
  currency?: string;
  whatsApp?: string;
  alternate?: string;
};

export enum Direction {
  TOP,
  RIGHT,
  BOTTOM,
  LEFT,
}

export type Faq = {
  answer: string;
  question: string;
};

export type Image = {
  description: string;
  height: number;
  url: string;
  width: number;
  title: string;
};

export type JsonRichText = {
  json: {
    content: {
      data: object;
      marks: {
        type: string;
      }[];
      nodeType: string;
      value: string;
    }[];
    data: object;
    nodeType: string;
  };
};

export interface ScriptWithKey extends HTMLScriptElement {
  key?: string;
}

export type MediaTypes = 'baphotos' | 'Image' | 'Video';

export type NormalizedSlide = {
  description: string;
  height: number | string;
  url: string;
  width: number | string;
};

export type Slide = Media | Asset | Image | APIBeforeAfterPhoto;

export const isAsset = (slide: Slide): slide is Asset => {
  return (slide as Asset).fields !== undefined;
};

export const isMedia = (slide: Slide): slide is Media => {
  return (slide as Media).media !== undefined;
};

export const isGqlImage = (slide: Slide): slide is Image => {
  return (slide as Image).url !== undefined;
};

export const isBeforeAfterPhoto = (
  slide: Slide,
): slide is APIBeforeAfterPhoto => {
  return (slide as APIBeforeAfterPhoto).aftermedia !== undefined;
};

export type Carousel = {
  children?: ReactNode | ReactNode[];
  counter?: boolean;
  expand?: boolean;
  hasAllImagesLoaded?: boolean;
  isLoading?: boolean;
  isMobile?: boolean;
  itemVariant: 'default' | 'before_after';
  onClose?: () => void;
  resolveItemComponent: (slide: Slide, isThumbnail?: boolean) => ReactNode;
  slides?: Slide[];
  startSlide?: number;
  thumbnails?: boolean;
  updateResultsByTreatment: (option: Record<string, string>) => void;
};

export type ApiResponse = Record<string, unknown> | string | null;
