import iconDefault from './iconDefaults';
import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const QuestionMark = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="34"
    height="58"
    viewBox="0 0 34 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.45 26.31L25.46 26.31L25.47 26.30C28.61 23.65 32 20.34 32 13.99C32 9.94 30.42 6.41 27.62 3.91C24.83 1.42 20.90 0 16.26 0C12.24 0 8.86 0.88 5.14 2.52C3.64 3.16 3 4.48 3 5.81C3 6.73 3.31 7.58 3.93 8.22C4.55 8.85 5.42 9.18 6.39 9.18C6.84 9.18 7.53 9.07 8.28 8.70C10.69 7.59 12.85 6.81 15.80 6.81C18.71 6.81 20.96 7.59 22.45 8.82C23.93 10.04 24.75 11.76 24.75 13.84C24.75 17.30 22.43 19.56 19.87 21.63C15.08 25.51 12.56 29.74 12.56 35.25V38.76C12.56 40.78 14.17 42.36 16.18 42.36C18.19 42.36 19.81 40.78 19.81 38.76V35.17C19.81 31.68 21.64 29.42 25.45 26.31ZM16.03 47.63C13.49 47.63 11.25 49.74 11.25 52.37C11.25 55.01 13.49 57.12 16.03 57.12C18.67 57.12 20.81 54.91 20.81 52.37C20.81 49.75 18.66 47.63 16.03 47.63Z"
      fill={color}
    />
  </svg>
);

export default QuestionMark;
