import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const TR = ({ getRotation, ...props }: IconProps): JSX.Element => (
  <svg {...props} viewBox="0 0 1200 800" xmlns="http://www.w3.org/2000/svg">
    <rect width="1200" height="800" fill="#E30A17" />
    <circle cx="425" cy="400" r="200" fill="#fff" />
    <circle cx="475" cy="400" r="160" fill="#E30A17" />
    <polygon
      points="583.33,400 764.23,458.77 652.43,304.89 652.43,495.10 764.23,341.22"
      fill="#fff"
    />
  </svg>
);

export default TR;
