import iconDefault from './iconDefaults';
import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const PaperClip = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="19"
    height="22"
    viewBox="0 0 19 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.446 3.60088C14.4111 2.55244 12.7317 2.55244 11.6968 3.60088L3.89328 11.5071C2.10779 13.3161 2.10779 16.2466 3.89328 18.0556C5.67877 19.8646 8.57118 19.8646 10.3567 18.0556L16.8031 11.5243C17.2654 11.056 18.0203 11.056 18.4826 11.5243C18.9448 11.9927 18.9448 12.7575 18.4826 13.2259L12.0361 19.7571C9.32185 22.5071 4.9281 22.5071 2.21381 19.7571C-0.500471 17.0071 -0.500471 12.5556 2.21381 9.80557L10.0174 1.89932C11.981 -0.0901368 15.1618 -0.0901368 17.1254 1.89932C19.089 3.88877 19.089 7.11143 17.1254 9.10088L9.66114 16.6634C8.44819 17.8923 6.48033 17.8923 5.26739 16.6634C4.05444 15.4345 4.05444 13.4407 5.26739 12.2118L11.3745 6.02432C11.8368 5.55596 12.5917 5.55596 13.054 6.02432C13.5163 6.49268 13.5163 7.25752 13.054 7.72588L6.94685 13.9134C6.6627 14.2013 6.6627 14.6739 6.94685 14.9618C7.231 15.2497 7.69752 15.2497 7.98167 14.9618L15.446 7.39932C16.4808 6.35088 16.4808 4.64932 15.446 3.60088Z"
      fill={color}
    />
  </svg>
);

export default PaperClip;
