import { Direction } from '@quno/patient-journey/types/Utilities';
import iconDefault from './iconDefaults';
import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const Arrow = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    height="24px"
    width="24px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    x="0px"
    y="0px"
    {...props}
  >
    <path
      transform={getRotation(Direction.BOTTOM, 24, 24)}
      fill={color}
      d="M2.25,7.33a1,1,0,0,0,.083,1.41l9,8a1,1,0,0,0,1.32,0l9-8a1,1,0,1,0-1.32-1.49L12,14.66,3.66,7.25A.9992.99,0,0,0,2.25,7.33Z"
    />
  </svg>
);

export default Arrow;
