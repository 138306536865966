import iconDefault from './iconDefaults';
import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const Languages = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.80 15C6.53 16.77 7.55 18.38 8.59 19.81C5.70 19.26 3.26 17.46 1.83 15L5.80 15ZM7.98 15C8.66 16.42 9.56 17.77 10.51 19.05C11.62 17.68 12.48 16.33 13.09 15H7.98ZM13.82 13H7.21C6.92 12.03 6.76 11.03 6.76 9.99C6.76 9.00 6.89 8.00 7.17 6.99L13.78 6.99C14.07 7.96 14.23 8.96 14.23 9.99C14.23 10.99 14.10 11.99 13.82 13ZM15.26 15C14.63 16.62 13.69 18.22 12.46 19.80C15.32 19.23 17.74 17.44 19.16 15L15.26 15ZM20.04 13H15.88C16.11 12.00 16.23 11.00 16.23 9.99C16.23 8.95 16.09 7.95 15.85 6.99L20.04 6.99C20.33 7.94 20.5 8.95 20.5 9.99C20.5 11.04 20.33 12.05 20.04 13ZM19.16 4.99C17.73 2.53 15.29 0.73 12.40 0.18C13.44 1.61 14.46 3.22 15.19 4.99H19.16ZM13.01 4.99C12.33 3.57 11.43 2.22 10.48 0.94C9.37 2.31 8.51 3.66 7.90 4.99H13.01ZM5.73 4.99C6.36 3.37 7.30 1.77 8.53 0.19C5.67 0.76 3.25 2.55 1.83 4.99H5.73ZM0.95 6.99C0.66 7.94 0.5 8.95 0.5 9.99C0.5 11.04 0.66 12.05 0.95 13H5.14C4.90 12.04 4.76 11.04 4.76 9.99C4.76 8.99 4.88 7.99 5.11 6.99L0.95 6.99Z"
      fill={color}
    />
  </svg>
);

export default Languages;
