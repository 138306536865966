import iconDefault from './iconDefaults';
import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const Quno = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="70"
    height="21"
    viewBox="0 0 70 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.10 11.36C8.28 11.15 8.39 10.87 8.39 10.57V8.31H10.67C10.75 8.31 10.84 8.30 10.92 8.29C11.07 8.26 11.22 8.19 11.35 8.11C11.68 7.89 11.89 7.52 11.89 7.10V4.68C11.89 4.26 11.68 3.89 11.35 3.68C11.22 3.59 11.07 3.52 10.92 3.49C10.84 3.48 10.75 3.47 10.67 3.47H9.51C9.10 3.74 8.73 4.06 8.40 4.42C7.41 5.50 6.80 6.94 6.80 8.51C6.80 8.94 6.85 9.36 6.94 9.77C7.00 10.04 7.07 10.31 7.17 10.56C7.26 10.82 7.37 11.07 7.50 11.31C7.56 11.43 7.63 11.55 7.70 11.66C7.75 11.64 7.80 11.61 7.84 11.58C7.94 11.52 8.02 11.44 8.10 11.36Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.33 12.74C17.17 12.90 17.01 13.05 16.84 13.19L15.43 11.30C15.16 10.94 14.64 10.86 14.27 11.13C14.03 11.30 13.92 11.57 13.93 11.83C13.93 11.94 13.96 12.04 14.00 12.14C14.03 12.19 14.06 12.24 14.09 12.29L15.41 14.06C14.66 14.40 13.82 14.58 12.94 14.58C10.72 14.58 8.78 13.41 7.70 11.66C7.63 11.55 7.56 11.43 7.50 11.31C7.37 11.07 7.26 10.82 7.17 10.56C7.07 10.31 7.00 10.04 6.94 9.77C6.85 9.36 6.80 8.94 6.80 8.51C6.80 6.93 7.41 5.50 8.40 4.42C8.73 4.06 9.10 3.74 9.51 3.47C10.49 2.81 11.67 2.43 12.94 2.43C16.32 2.43 19.07 5.16 19.07 8.51C19.07 9.09 18.98 9.66 18.82 10.20C18.76 10.42 18.69 10.63 18.60 10.83C18.51 11.04 18.42 11.24 18.31 11.43C18.20 11.62 18.09 11.81 17.96 11.99C17.90 12.08 17.83 12.16 17.76 12.25C17.63 12.42 17.48 12.59 17.33 12.74ZM17.83 14.52C19.60 13.10 20.74 10.93 20.74 8.51C20.74 4.24 17.24 0.77 12.94 0.77C11.24 0.77 9.67 1.31 8.39 2.23V1.21C8.39 0.54 7.84 0 7.17 0H4.72C4.05 0 3.50 0.54 3.50 1.21V3.47H1.22C0.55 3.47 0 4.01 0 4.68V7.10C0 7.77 0.55 8.31 1.22 8.31H3.50V10.57C3.50 11.24 4.05 11.79 4.72 11.79H5.87C7.12 14.41 9.82 16.24 12.94 16.24C14.19 16.24 15.37 15.94 16.43 15.42L19.63 19.72C19.80 19.94 20.05 20.05 20.30 20.05C20.48 20.05 20.65 20.00 20.80 19.89C21.17 19.62 21.25 19.10 20.98 18.73L17.83 14.52Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.12 4.43C32.59 4.43 32.29 4.73 32.29 5.22V10.48C32.29 12.95 30.21 14.77 27.99 14.77C26.14 14.77 24.45 13.32 24.45 11.23V5.22C24.45 4.73 24.10 4.43 23.60 4.43C23.07 4.43 22.78 4.73 22.78 5.22V11.23C22.78 14.50 25.32 16.31 27.97 16.31C29.75 16.31 31.51 15.58 32.34 13.93V15.36C32.34 16.45 33.99 16.42 33.99 15.38V5.22C33.99 4.73 33.64 4.43 33.12 4.43Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.61 4.34C40.83 4.34 39.06 5.04 38.24 6.70V5.29C38.24 4.20 36.59 4.23 36.59 5.27V15.43C36.59 15.93 36.94 16.22 37.46 16.22C37.99 16.22 38.29 15.93 38.29 15.43V10.17C38.29 7.70 40.37 5.88 42.59 5.88C44.44 5.88 46.13 7.33 46.13 9.42V15.43C46.13 15.93 46.48 16.22 46.98 16.22C47.51 16.22 47.80 15.93 47.80 15.43V9.42C47.80 6.15 45.26 4.34 42.61 4.34Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.93 13.34C58.10 14.25 56.80 14.84 55.53 14.84C54.23 14.84 52.98 14.30 52.15 13.34C51.44 12.55 51.11 11.48 51.11 10.45C51.11 8.40 52.27 6.63 54.25 6.13C54.61 6.03 55.05 5.97 55.50 5.97C55.95 5.97 56.42 6.03 56.80 6.13C58.81 6.63 59.97 8.43 59.97 10.50C59.97 11.48 59.59 12.57 58.93 13.34ZM61.52 9.98C61.31 7.44 59.76 5.36 57.26 4.65C56.67 4.49 56.12 4.45 55.57 4.45C55.00 4.45 54.38 4.49 53.83 4.65C51.32 5.36 49.83 7.46 49.60 9.98C49.53 11.68 49.97 13.13 50.99 14.33C52.12 15.67 53.74 16.37 55.57 16.37C57.38 16.37 59.02 15.67 60.15 14.33C61.15 13.13 61.61 11.68 61.52 9.98Z"
      fill={color}
    />
  </svg>
);

export default Quno;
