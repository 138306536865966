import { Direction } from '@quno/patient-journey/types/Utilities';
import iconDefault from './iconDefaults';
import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const Angle = ({
  color = iconDefault.color,
  getRotation,
  className,
  width = 16,
  height = 16,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    className={className}
    height={height}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 25"
    x="0px"
    y="0px"
  >
    <path
      fill={color || 'currentColor'}
      transform={`${getRotation(
        Direction.RIGHT,
        14,
        25,
      )} translate(-22 -17.75)`}
      fillRule="evenodd"
      d="M24.93,42.15 C24.26,42.82 23.17,42.82 22.50,42.15 C21.83,41.48 21.83,40.39 22.50,39.72 L31.84,30.37 L22.50,21.03 C21.83,20.36 21.83,19.27 22.50,18.60 C23.17,17.93 24.26,17.93 24.93,18.60 L35.49,29.16 C35.83,29.49 36,29.93 36,30.37 C36,30.81 35.83,31.25 35.49,31.59 L24.93,42.15 Z"
    />
  </svg>
);

export default Angle;
