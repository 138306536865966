import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const ES = ({ getRotation, ...props }: IconProps): JSX.Element => (
  <svg {...props} viewBox="0 0 640 480" xmlns="http://www.w3.org/2000/svg">
    <rect width="750" height="500" fill="#c60b1e" />
    <rect width="750" height="250" fill="#ffc400" y="125" />
  </svg>
);

export default ES;
