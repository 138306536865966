import iconDefault from './iconDefaults';
import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const Gender = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="51"
    height="52"
    viewBox="0 0 51 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.2 0.60H48.2C49.1 0.60 49.89 1.40 50.09 2.30V8.30C50.09 9.20 49.3 10 48.4 10C47.5 10 46.7 9.20 46.7 8.30V6.40L43.79 9.30C45.9 12.1 47.09 15.5 47.09 19.1C47.09 23.4 45.4 27.5 42.29 30.6C39.2 33.7 35.1 35.4 30.79 35.4C26.5 35.4 22.4 33.7 19.3 30.6C18.2 29.5 17.2 28.2 16.5 26.8C16.1 26 16.4 24.9 17.2 24.5C18 24.1 19.1 24.4 19.5 25.2C20.1 26.3 20.8 27.3 21.7 28.2C24.1 30.7 27.29 32 30.79 32C34.29 32 37.5 30.6 39.9 28.2C44.9 23.1 44.9 15 39.9 10C37.5 7.50 34.29 6.20 30.79 6.20C27.29 6.20 24.1 7.60 21.7 10C19.8 11.8 18.6 14.2 18.1 16.8C17.9 17.7 17 18.4 16.1 18.2C15.2 18 14.5 17.1 14.7 16.2C15.3 12.9 16.8 9.90 19.2 7.50C22.3 4.40 26.4 2.70 30.7 2.70C34.59 2.70 38.29 4.10 41.29 6.70L44 4.00H42.2C41.29 4.00 40.5 3.20 40.5 2.30C40.5 1.40 41.29 0.60 42.2 0.60ZM32.29 38C32.59 37 33.5 36.5 34.4 36.7C35.3 36.9 35.9 37.9 35.7 38.8C35 41.7 33.5 44.4 31.4 46.5C28.3 49.6 24.2 51.3 19.9 51.3C15.6 51.3 11.5 49.6 8.39 46.5C5.29 43.4 3.59 39.3 3.59 35C3.59 31.2 4.89 27.6 7.19 24.7L4.79 22.3L2.99 24.1C2.69 24.4 2.19 24.6 1.79 24.6C1.39 24.6 0.89 24.4 0.59 24.1C-0.10 23.5 -0.10 22.4 0.59 21.7L2.39 19.9L1.49 19C0.79 18.4 0.79 17.3 1.49 16.6C2.09 15.9 3.19 15.9 3.89 16.6L4.79 17.5L6.59 15.7C7.19 15 8.29 15 8.99 15.7C9.69 16.3 9.69 17.4 8.99 18.1L7.19 19.9L9.59 22.3C12.5 20 16.1 18.7 19.9 18.7C24.2 18.7 28.3 20.4 31.4 23.5C32.5 24.6 33.4 25.8 34.1 27.1C34.5 27.9 34.2 28.9 33.4 29.4C32.6 29.8 31.6 29.5 31.1 28.7C30.6 27.7 29.79 26.7 29 25.9C26.6 23.4 23.4 22.1 19.9 22.1C16.4 22.1 13.2 23.5 10.8 25.9C8.29 28.3 6.99 31.6 6.99 35C6.99 38.4 8.39 41.6 10.7 44.1C13.1 46.6 16.4 47.9 19.8 47.9C23.3 47.9 26.5 46.5 28.9 44.1C30.6 42.4 31.69 40.3 32.29 38Z"
      fill={color}
    />
  </svg>
);

export default Gender;
