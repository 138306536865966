import iconDefault from './iconDefaults';
import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const Badge = ({
  color = iconDefault.color,
  getRotation,
  className,
  width = 16,
  height = 16,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    className={className}
    height={height}
    width={width}
    viewBox="0 0 21 21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="5.83337"
      y="5.83334"
      width="9.33333"
      height="9.33333"
      rx="4.66667"
      fill={color || 'currentColor'}
    />
    <rect
      opacity="0.25"
      width="21"
      height="21"
      rx="10.5"
      fill={color || 'currentColor'}
    />
    <rect
      opacity="0.5"
      x="3.5"
      y="3.5"
      width="14"
      height="14"
      rx="7"
      fill={color || 'currentColor'}
    />
  </svg>
);

export default Badge;
