import iconDefault from './iconDefaults';
import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const Pdf = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 2.75C0.5 1.2332 1.7332 0 3.25 0H10.125V5.5C10.125 6.26055 10.7395 6.875 11.5 6.875H17V13.0625H8.0625C6.5457 13.0625 5.3125 14.2957 5.3125 15.8125V22H3.25C1.7332 22 0.5 20.7668 0.5 19.25V2.75ZM17 5.5H11.5V0L17 5.5ZM8.0625 15.125H9.4375C10.7652 15.125 11.8438 16.2035 11.8438 17.5312C11.8438 18.859 10.7652 19.9375 9.4375 19.9375H8.75V21.3125C8.75 21.6906 8.44063 22 8.0625 22C7.68437 22 7.375 21.6906 7.375 21.3125V19.25V15.8125C7.375 15.4344 7.68437 15.125 8.0625 15.125ZM9.4375 18.5625C10.009 18.5625 10.4688 18.1027 10.4688 17.5312C10.4688 16.9598 10.009 16.5 9.4375 16.5H8.75V18.5625H9.4375ZM13.5625 15.125H14.9375C16.0762 15.125 17 16.0488 17 17.1875V19.9375C17 21.0762 16.0762 22 14.9375 22H13.5625C13.1844 22 12.875 21.6906 12.875 21.3125V15.8125C12.875 15.4344 13.1844 15.125 13.5625 15.125ZM14.9375 20.625C15.3156 20.625 15.625 20.3156 15.625 19.9375V17.1875C15.625 16.8094 15.3156 16.5 14.9375 16.5H14.25V20.625H14.9375ZM18.375 15.8125C18.375 15.4344 18.6844 15.125 19.0625 15.125H21.125C21.5031 15.125 21.8125 15.4344 21.8125 15.8125C21.8125 16.1906 21.5031 16.5 21.125 16.5H19.75V17.875H21.125C21.5031 17.875 21.8125 18.1844 21.8125 18.5625C21.8125 18.9406 21.5031 19.25 21.125 19.25H19.75V21.3125C19.75 21.6906 19.4406 22 19.0625 22C18.6844 22 18.375 21.6906 18.375 21.3125V18.5625V15.8125Z"
      fill={color}
    />
  </svg>
);

export default Pdf;
