import iconDefault from './iconDefaults';
import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const LinkedIn = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M4.98 3.5c0 1.38-1.11 2.5-2.48 2.5s-2.48-1.11-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.98 0h-4.96v16h4.96v-8.39c0-4.67 6.02-5.05 6.02 0v8.39h4.98v-10.13c0-7.88-8.92-7.59-11.01-3.71v-2.15z"
    />
  </svg>
);

export default LinkedIn;
