import iconDefault from './iconDefaults';
import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const FaqQuestionMark = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 5C2.5 3.89687 3.39687 3 4.5 3H5.5C6.60313 3 7.5 3.89687 7.5 5V5.1125C7.5 5.79375 7.15313 6.42812 6.58125 6.79375L5.2625 7.64062C4.475 8.14687 4 9.01875 4 9.95312V10C4 10.5531 4.44688 11 5 11C5.55312 11 6 10.5531 6 10V9.95625C6 9.7 6.13125 9.4625 6.34375 9.325L7.6625 8.47812C8.80625 7.74062 9.5 6.475 9.5 5.1125V5C9.5 2.79063 7.70937 1 5.5 1H4.5C2.29063 1 0.5 2.79063 0.5 5C0.5 5.55312 0.946875 6 1.5 6C2.05313 6 2.5 5.55312 2.5 5ZM5 15C5.33152 15 5.64946 14.8683 5.88388 14.6339C6.1183 14.3995 6.25 14.0815 6.25 13.75C6.25 13.4185 6.1183 13.1005 5.88388 12.8661C5.64946 12.6317 5.33152 12.5 5 12.5C4.66848 12.5 4.35054 12.6317 4.11612 12.8661C3.8817 13.1005 3.75 13.4185 3.75 13.75C3.75 14.0815 3.8817 14.3995 4.11612 14.6339C4.35054 14.8683 4.66848 15 5 15Z"
      fill={color}
    />
  </svg>
);

export default FaqQuestionMark;
