import { Direction } from '@quno/patient-journey/types/Utilities';
import iconDefault from './iconDefaults';
import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const CalendarError = ({
  color = iconDefault.color,
  getRotation,
  className,
  width = 16,
  height = 16,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    className={className}
    height={height}
    width={width}
    viewBox="0 0 23 23"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={color || 'currentColor'}
      transform={getRotation(Direction.RIGHT, 24, 24)}
      d="M14.8929 1.98438C14.8929 1.43887 15.3228 1 15.8571 1C16.3915 1 16.8214 1.43887 16.8214 1.98438V3.625H18.4286C19.8469 3.625 21 4.80215 21 6.25V6.90625V8.875V19.375C21 20.8229 19.8469 22 18.4286 22H5.57143C4.15313 22 3 20.8229 3 19.375V8.875V6.90625V6.25C3 4.80215 4.15313 3.625 5.57143 3.625H7.17857V1.98438C7.17857 1.43887 7.60848 1 8.14286 1C8.67723 1 9.10714 1.43887 9.10714 1.98438V3.625H14.8929V1.98438ZM19.0714 8.875H15.8571V11.1719H19.0714V8.875ZM19.0714 13.1406H15.8571V15.7656H19.0714V13.1406ZM13.9286 13.1406H10.0714V15.7656H13.9286V13.1406ZM8.14286 13.1406H4.92857V15.7656H8.14286V13.1406ZM4.92857 11.1719H8.14286V8.875H4.92857V11.1719ZM4.92857 17.7344V19.375C4.92857 19.7359 5.21786 20.0312 5.57143 20.0312H8.14286V17.7344H4.92857ZM10.0714 17.7344V20.0312H13.9286V17.7344H10.0714ZM15.8571 17.7344V20.0312H18.4286C18.7821 20.0312 19.0714 19.7359 19.0714 19.375V17.7344H15.8571ZM10.0714 11.1719H13.9286V8.875H10.0714V11.1719Z"
    />
  </svg>
);

export default CalendarError;
