import iconDefault from './iconDefaults';
import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const Chevron = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    viewBox="0 0 22 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.2943 11.4979C11.5784 12.1674 10.4159 12.1674 9.69999 11.4979L0.5369 2.92836C-0.178966 2.25887 -0.178966 1.17161 0.536901 0.502118C1.25277 -0.167375 2.41533 -0.167374 3.1312 0.502118L11 7.86119L18.8688 0.507477C19.5847 -0.162017 20.7472 -0.162017 21.4631 0.507477C22.179 1.17697 22.179 2.26423 21.4631 2.93372L12.3 11.5032L12.2943 11.4979Z"
      fill={color}
    />
  </svg>
);
export default Chevron;
