import tailwindConfig from '@quno/patient-journey/.cache/tailwind.json';

const breakpointMap = createBreakpointMap(tailwindConfig.theme.screens);

export function createBreakpointMap(
  screens: Record<string, string>,
): Map<string, number> {
  // Maps guarantee the order of properties
  const breakpointMap = new Map();

  // Swap key/values from screens
  // for faster access setting the
  // map values
  const swappedScreens = {};

  Object.keys(screens)
    .map((id) => {
      const num = parseInt(screens[id], 10);
      // eslint-disable-next-line
      // @ts-ignore
      swappedScreens[num] = id;
      return num;
    })
    .sort((a, b) => a - b)
    .forEach((size) => {
      const id = swappedScreens[size as keyof typeof swappedScreens];

      breakpointMap.set(id, size);
    });

  return breakpointMap;
}

/**
 * Returns the viewport name for the given a width
 */
export function findViewport(width: number): string {
  let lastViewport = '';

  if (typeof width !== 'number') {
    throw new TypeError('Argument must be a number');
  }

  for (const [key, value] of breakpointMap.entries()) {
    if (value > width) {
      return lastViewport;
    }

    lastViewport = key;
  }

  return lastViewport;
}

/**
 * Return true if the given viewportName
 * maps to a small screen viewport.
 */
export function isSmallScreen(viewportName: string): boolean {
  if (!viewportName) {
    // First breakpoint currently is "sm" at 640.
    // Before that the viewport doesn't have a name
    // (and therefore assumed to be small)
    return true;
  }

  if (!breakpointMap.has(viewportName)) {
    throw new Error(`Invalid viewport name: "${viewportName}"`);
  }

  return viewportName === 'xs' || viewportName === 'sm';
}

/**
 * Get index of the given viewport name
 * in the breakpoints map
 */
export function getBreakpointIndex(name: string): number {
  return Array.from(breakpointMap.keys()).indexOf(name);
}

export default tailwindConfig;
