import iconDefault from './iconDefaults';
import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const Plus = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.33 1.66C8.33 0.93 7.73 0.33 7 0.33C6.26 0.33 5.66 0.93 5.66 1.66V5.66H1.66C0.93 5.66 0.33 6.26 0.33 7.00C0.33 7.73 0.93 8.33 1.66 8.33H5.66V12.33C5.66 13.06 6.26 13.66 7 13.66C7.73 13.66 8.33 13.06 8.33 12.33V8.33H12.33C13.06 8.33 13.66 7.73 13.66 7.00C13.66 6.26 13.06 5.66 12.33 5.66H8.33V1.66Z"
      fill={color}
    />
  </svg>
);

export default Plus;
