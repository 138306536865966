import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const LT = ({ getRotation, ...props }: IconProps): JSX.Element => (
  <svg {...props} viewBox="0 0 640 480" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 320h640v160.00H0z" fill="#bb262c" />
    <path d="M0 0h640v160H0z" fill="#f5b312" />
    <path d="M0 160h640v160H0z" fill="#006742" />
  </svg>
);

export default LT;
