import { Direction } from '@quno/patient-journey/types/Utilities';
import iconDefault from './iconDefaults';
import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const Menu = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -53 384 384"
    width="20px"
    height="20px"
    {...props}
  >
    <g
      transform={getRotation(Direction.RIGHT, 384, 384)}
      fill={color || 'currentColor'}
    >
      <path d="m368 154.66h-352c-8.83 0-16-7.16-16-16s7.16-16 16-16h352c8.83 0 16 7.16 16 16s-7.16 16-16 16zm0 0" />
      <path d="m368 32h-352c-8.83 0-16-7.16-16-16s7.16-16 16-16h352c8.83 0 16 7.16 16 16s-7.16 16-16 16zm0 0" />
      <path d="m368 277.33h-352c-8.83 0-16-7.16-16-16s7.16-16 16-16h352c8.83 0 16 7.16 16 16s-7.16 16-16 16zm0 0" />
    </g>
  </svg>
);

export default Menu;
