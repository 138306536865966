import iconDefault from './iconDefaults';
import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const SatisfactionGuarantee = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.46 22.98L17.27 24.90L22.51 30.73L22.52 30.72L22.53 30.73L31.92 21.19L30.24 18.99L22.61 25.27L19.46 22.98Z"
      fill={color}
    />
    <path
      d="M10.77 16.36C10.77 16.36 11.65 37.26 23.99 37.26C36.34 37.26 37.22 16.36 37.22 16.36L23.99 10.73L10.77 16.36Z"
      stroke={color}
      strokeWidth="3"
    />
    <circle cx="24" cy="24" r="22.5" stroke={color} strokeWidth="3" />
  </svg>
);

export default SatisfactionGuarantee;
