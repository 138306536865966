import iconDefault from './iconDefaults';
import type { IconProps } from '@quno/patient-journey/src/components/ui/Icon';

const Stethoscope = ({
  color = iconDefault.color,
  getRotation,
  ...props
}: IconProps): JSX.Element => (
  <svg
    {...props}
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.68617 0.855467C4.90978 1.51172 4.54641 2.21875 3.87957 2.4375L2.83339 2.77734V7.5C2.83339 9.57031 4.5504 11.25 6.66672 11.25C8.78304 11.25 10.5001 9.57031 10.5001 7.5V2.77734L9.45787 2.4375C8.78703 2.21875 8.42766 1.51172 8.65127 0.855467C8.87488 0.199217 9.59762 -0.152345 10.2685 0.0664046L11.3106 0.406248C12.3528 0.746092 13.0556 1.69922 13.0556 2.77734V7.5C13.0556 10.5156 10.8754 13.0312 7.97245 13.6211C8.22401 15.8047 10.1167 17.5 12.4167 17.5C14.8884 17.5 16.8889 15.543 16.8889 13.125V10.3633C15.7589 9.88281 14.9723 8.78125 14.9723 7.5C14.9723 5.77344 16.4018 4.375 18.1667 4.375C19.9317 4.375 21.3612 5.77344 21.3612 7.5C21.3612 8.78125 20.5745 9.88281 19.4445 10.3633V13.125C19.4445 16.9219 16.298 20 12.4167 20C8.70717 20 5.67245 17.1914 5.40891 13.6289C2.482 13.0547 0.277832 10.5312 0.277832 7.5V2.77734C0.277832 1.70312 0.98061 0.746092 2.02679 0.406248L3.06898 0.0664046C3.73981 -0.152345 4.46255 0.203123 4.68617 0.855467ZM18.1667 8.75C18.5056 8.75 18.8306 8.6183 19.0702 8.38388C19.3099 8.14946 19.4445 7.83152 19.4445 7.5C19.4445 7.16848 19.3099 6.85054 19.0702 6.61612C18.8306 6.38169 18.5056 6.25 18.1667 6.25C17.8278 6.25 17.5028 6.38169 17.2632 6.61612C17.0236 6.85054 16.8889 7.16848 16.8889 7.5C16.8889 7.83152 17.0236 8.14946 17.2632 8.38388C17.5028 8.6183 17.8278 8.75 18.1667 8.75Z"
      fill={color}
    />
  </svg>
);

export default Stethoscope;
